import { apiService } from './apiService'

export default {
  async login(username: string, password: string) {
    try {
      const response = await apiService.post('/oauth/token', {
        username,
        password,
        grant_type: 'password',
      })
      return response.data
    } catch (error) {
      const err = error as any
      if (err.response) {
        if (err.response.data) {
          throw new Error(err.response.data)
        } else {
          throw new Error('Unknown error')
        }
      }
    }
  },
  isAuthenticated() {
    const token = this.getToken()
    return !!token
  },
  getToken() {
    return localStorage.getItem('access_token')
  },
}
