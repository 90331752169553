import axios from 'axios'
import router from '../router'

// const token = localStorage.getItem('access_token')
const url = localStorage.getItem('domainURL') || ''

export const apiService = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    // Authorization: token ? `Bearer ${token}` : '',
  },
})

apiService.interceptors.request.use((config) => {
  const token = localStorage.getItem('access_token')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
})

apiService.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = localStorage.getItem('refresh_token')
      try {
        const { data } = await axios.post(`${url}/oauth/token`, {
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
        })
        localStorage.setItem('access_token', data.access_token)
        apiService.defaults.headers['Authorization'] = `Bearer ${data.access_token}`
        originalRequest.headers['Authorization'] = `Bearer ${data.access_token}`
        return apiService(originalRequest)
      } catch (err) {
        console.error('Refresh token failed', err)
        router.push('/auth/login')
      }
    }
    return Promise.reject(error)
  },
)
